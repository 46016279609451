import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { TemplateModel1 } from 'src/app/models/dataloads';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { AcctSdmService } from 'src/app/services/acct-sdm.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { DatePipe, formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-acct-sdm',
  templateUrl: './acct-sdm.component.html',
  styleUrls: ['./acct-sdm.component.css'],
})
export class AcctSdmComponent implements OnInit {
  visible = true;
  //loadTemplelatesColumns = ['template_name',  'download', 'upload', 'download1'];
  public tempRecord: any = [];
  displayColumns = [
    'radio',
    'firstEffectiveDate',
    'lastEffectiveDate',
    'staus',
    'inceptDate',
    'inceptAmt',
    'termdate',
    'xaumFlag',
    'aumRpt',
    'tisActType',
    'primaryMgr',
    'primaryMgrName',
    'portfolioMgr',
    'portfolioMgrName',
    'assitMgrID',
    'assitMgrName',
    'ficalYE',
    'gStatus',
    'domicile',
    'subAdvisor',
    'ssAdvisor',
    'mutFundType',
    'transferAgent',
    'transferAgentDesc',
    'tax',
    'taxDesc',
    'invstObj',
    'invstObjDesc',
    'busMgrDesc',
    'busMgrName',
    'adminLoc',
    'acctName',
    'bckMgrID',
    'bckMgrName',
    'cwg',
    'imcCode',
    '13fginvstDis',
    '13fgVoting',
    '13fgAdvCode',
    '13fgAdvName',
    '13fgInclude',
  ];
  @ViewChild('tempsort', { static: true }) tempsort = new MatSort();
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild('panel1') firstPanel: MatExpansionPanel;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public templateList: TemplateModel1[] = [];
  loadTemplateDatasource: MatTableDataSource<any> = new MatTableDataSource();
  panelOpenState: boolean;
  public tempName;
  pageDirty: boolean = false;
  accountNo: string = '';
  isLoading: boolean = false;
  accountType: string = '';
  accountName: string = '';
  lastUpdatedDate: string = '';
  lastUpdatedBy: string = '';
  firstEffectiveDate: Date;
  lastEffectiveDate: Date;
  inceptionDate: Date;
  terminationDate: Date;
  CWG: string = '';
  mutFundType: string = '';
  inceptAmt: string = '';
  imcCode: string = '';
  subAdvisor: string = '';
  ssAdvisor: string = '';
  primaryMgrID: number = 0;
  bckMgrID: number = 0;
  subAdvisorDesc: string = '';
  ssAdvisorDesc: string = '';
  ficalYE: string = '';
  transferAgent: string = '';
  transferAgentDesc: string = '';
  taxability: string = '';
  taxabilityDesc: string = '';
  invstObj: string = '';
  invstObjDesc: string = '';
  adminLoc: string = '';
  adminLocDesc: string = '';
  invstDis: string = '';
  Voting: string = '';
  invInclude: string = '';
  gStatus: string = '';
  acctStatus: string = '';
  domicile: String = '';
  bckportManagerID: number = 0;
  assitMgrID: number = 0;
  invstAdvID: number = 0;
  xaumFlag: boolean = false;
  aumRpt: string = '';
  businessMgr: string = '';
  businessMgrDesc: string = '';
  isChecked = false;
  default: number = 1;
  grpmgrList: any = [];
  invstAdvList: any = [];
  public lastRecord: any = [];
  public firstRecord: any = [];
  isEdit: boolean = false;
  validateinfo: string = '';
  showView: boolean = false;
  enable: boolean = false;
  selectedrecord: string = '';

  varGrpmgrText;
  varPortText;
  varInvstText;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private accountservice: AcctSdmService,
    private toastr: ToasterService,
    private datepipe: DatePipe,
    private confirmDialog: DialogService
  ) {
    this.accountNo = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.getAccountSdmInformation();
  }
  canDeactivate(): Observable<boolean> | boolean {
    console.log('can', this.pageDirty);
    if (this.pageDirty) {
      return this.confirmDialog.confirm('Are you sure you want to leave?');
    }
    return true;
  }
  getAccountSdmInformation() {
    this.isLoading = true;
    this.accountservice
      .getAccountAcctSdmInfo(this.accountNo)
      .subscribe((result: any) => {
        if (result.status == 200 && result.data != null) {
          this.loadTemplateDatasource = new MatTableDataSource(result.data);
          this.loadTemplateDatasource.sort = this.tempsort;
          this.loadTemplateDatasource.paginator = this.paginator;
          this.lastRecord = result.data[result.data.length - 1];
          this.firstRecord = result.data[0];
          this.isLoading = false;
          this.validateAccountAcctSdmInformation();
          this.getAdvisorList();
          this.getgroupmgrList();
        }
      });
  }
  bindHtml(output: any) {
    let finalvalue = '';

    finalvalue = '<table><tr>';
    finalvalue += '<td class="col-16">' + output + '</td></tr></table>';

    return finalvalue;
  }
  validateAccountAcctSdmInformation() {
    var outputdata: any[];
    // let messages='';
    this.accountservice
      .validateACCTHistory(this.accountNo)
      .subscribe((result: any) => {
        if (result.status == 200 && result.data != null) {
          this.validateinfo = '<table><tr>';
          this.validateinfo +=
            '<td class="col-16"><font color="#FF0000">Data is inconsistent for the following effective dates:</font></td></tr></table>';

          if (result.data.meesage_1 != null) {
            outputdata = result.data.meesage_1.split('|');
            this.validateinfo +=
              '<table><tr><td class="col-16">First Effective date is greater than Last Effective date for :</td></tr>';
            this.validateinfo += '</table>';
            for (var xy of outputdata) {
              let myArray = xy.split('-');
              myArray.splice(0, 1);
              this.validateinfo += this.bindHtml(
                myArray[0] + ' - ' + myArray[1]
              );
            }
          }
          if (result.data.meesage_2 != null) {
            outputdata = result.data.meesage_2.split('|');
            this.validateinfo +=
              '<table><tr><td class="col-16">There is a time gap between the Last Effective date and the First Effective date for:</td></tr>';
            this.validateinfo += '</table>';
            for (var xy of outputdata) {
              let myArray = xy.split('-');
              myArray.splice(0, 1);
              this.validateinfo += this.bindHtml(
                myArray[0] + ' - ' + myArray[1]
              );
            }
          }
        }
      });
  }
  getgroupmgrList() {
    this.accountservice.getgroupmgrList().subscribe((result) => {
      this.grpmgrList = result.data;
    });
  }
  getAdvisorList() {
    this.accountservice.getAdvisorList().subscribe((result) => {
      this.invstAdvList = result.data;
    });
  }

  redirectToAUMAcctSdm() {
    this.router.navigate(['/aum-acct-sdm', { id: this.accountNo }]);
  }
  redirectToAccountPage() {
    this.router.navigate(['/account-attributes', { id: this.accountNo }]);
  }
  closePanel() {
    this.firstPanel.close();
    this.pageDirty = false;
  }
  loadDetailsPanel() {
    this.pageDirty = true;
    this.isEdit = false;
    //this.router.navigate(['/load-acct-details'], { queryParams: { a:value, b: editable, c:index } })
    if (this.tempRecord['acct_dim_key'] != undefined) {
      this.showView = true;
      this.selectedrecord = this.tempRecord['acct_dim_key'];
      this.firstPanel.open();
      this.fillAccountInfo(this.tempRecord);

      //if (this.firstRecord['acct_dim_key'] != this.tempRecord['acct_dim_key']) {
        //this.isEdit = true;
      //}
      if((this.tempRecord['last_effective_date'] == null || this.tempRecord["last_effective_date"] == "")){
        this.isEdit = false;
      }else{
        this.isEdit = true;
      }
    }
  }
  viewAttribute(event: any) {
    this.tempRecord = event.value;
  }
  checkValue(event: any) {
    this.tempRecord.xaum_flag = event;
  }
  fillAccountInfo(source: any) {
    this.accountNo = source.acct_num;
    this.accountType = source.tis_acct_type;
    this.accountName = source.acct_name;
    this.lastUpdatedDate = formatDate(
      source.system_last_updated_date,
      'MM/dd/yyyy hh:mm:ss a',
      'en_US'
    );
    this.lastUpdatedBy = source.system_last_updated_by_user_id;
    this.firstEffectiveDate =
      source.first_effective_date != null
        ? this.datepipe.transform(
            source.first_effective_date,
            'MM/dd/yyyy hh:mm:ss a'
          )
        : source.first_effective_date;
    this.lastEffectiveDate =
      source.last_effective_date != null
        ? this.datepipe.transform(
            source.last_effective_date,
            'MM/dd/yyyy hh:mm:ss a'
          )
        : source.last_effective_date;
    this.CWG = source.acct_chinese_wall_group_code;
    this.mutFundType = source.acct_mut_fund_type;
    this.inceptAmt = source.acct_incept_amt;
    this.imcCode = source.invest_mgmt_company_code;
    this.subAdvisor = source.sub_advisor;
    this.ssAdvisor = source.ssadvisor;
    this.primaryMgrID = source.acct_primary_mgr_id;
    this.bckMgrID = source.acct_backup_mgr_id;
    this.subAdvisorDesc = source.sub_advisor_desc;
    this.ssAdvisorDesc = source.sub_sub_advisor_desc;
    this.ficalYE = source.fiscal_year_end;
    this.transferAgent = source.transfer_agent;
    this.transferAgentDesc = source.transfer_agent_desc;
    this.taxability = source.taxability;
    this.taxabilityDesc = source.taxability_desc;
    this.invstObj = source.ici_invest_objective;
    this.invstObjDesc = source.ici_invest_objective_desc;
    this.adminLoc = source.admin_location;
    this.adminLocDesc = source.admin_location_desc;
    this.invstDis = source.acct_invst_discretion;
    this.Voting = source.acct_voting_authority;
    this.invInclude = source.acct_include_13fgd;
    this.gStatus = source.gstatus;
    this.acctStatus = source.acct_status;
    this.domicile = source.domicile;
    this.bckportManagerID = source.portfolio_manager;
    this.assitMgrID = source.acct_assistant_mgr_id;
    this.invstAdvID = source.acct_invst_adv_code;
    this.xaumFlag = source.xaum_flag == 'Yes' ? true : false;
    this.aumRpt = source.aumrpt;
    this.businessMgr = source.business_manager;
    this.businessMgrDesc = source.business_manager_desc;
    this.terminationDate =
      source.acct_termination_date != null
        ? this.datepipe.transform(source.acct_termination_date, 'yyyy-MM-dd')
        : source.acct_termination_date;
    this.inceptionDate =
      source.acct_incept_date != null
        ? this.datepipe.transform(source.acct_incept_date, 'yyyy-MM-dd')
        : source.acct_incept_date;
    this.enable = source.last_effective_date != null ? false : true;
  }
  copyAsNewRecord() {
    const accountinfo = {
      p_acct_dim_key: this.tempRecord['acct_dim_key'],
    };
    this.accountservice
      .copyHistoryACCTRecord(accountinfo)
      .subscribe((result: any) => {
        if (result.status == 200) {
          this.toastr.success('New Record created Scuccessfully');
          this.getAccountSdmInformation();
        }
      });
  }
  validateDate(date: Date) {
    var regex =
      /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;
    let value = new Date(date);

    if (isNaN(value.getTime()) || value <= new Date('01/01/1753')) {
      return false;
    } else {
      return true;
    }
  }
  saveAccountSDIMInfo() {
    // let isValid = this.validateDate(this.firstEffectiveDate);
    // if(isValid == false)
    // {
    //   return this.toastr.error("Please enter valid date for first effective date");
    // }
    //  isValid = this.validateDate(this.lastEffectiveDate);
    // if(isValid == false)
    // {
    //   return this.toastr.error("Please enter valid date for Last effective date");
    // }

    let accountacctinfo = {
      p_acct_dim_key: this.tempRecord.acct_dim_key,
      p_acct_chinese_wall_group_code: this.CWG,
      p_acct_mut_fund_type: this.mutFundType,
      p_acct_incept_date:
        this.inceptionDate != null
          ? formatDate(
              this.inceptionDate,
              'MM/dd/yyyy hh:mm:ssZZZZZ',
              'en_US'
            ).slice(0, 10)
          : this.inceptionDate,
      p_acct_incept_amt: this.inceptAmt,
      p_acct_termination_date:
        this.terminationDate != null
          ? formatDate(
              this.terminationDate,
              'MM/dd/yyyy hh:mm:ssZZZZZ',
              'en_US'
            ).slice(0, 10)
          : this.terminationDate,
      p_invest_mgmt_company_code: this.imcCode,
      p_acct_primary_mgr_id: this.primaryMgrID,
      p_acct_backup_mgr_id: this.bckMgrID,
      p_sub_advisor: this.subAdvisor,
      p_ssadvisor: this.ssAdvisor,
      p_fiscal_year_end: this.ficalYE,
      p_transfer_agent: this.transferAgent,
      p_business_manager: this.businessMgr,
      p_portfolio_manager: this.bckportManagerID,
      p_taxability: this.taxability,
      p_admin_location: this.adminLoc,
      p_ici_invest_objective: this.invstObj,
      p_domicile: this.domicile,
      p_transfer_agent_desc: this.transferAgentDesc,
      p_admin_location_desc: this.adminLocDesc,
      p_ici_invest_objective_desc: this.invstObjDesc,
      p_aumrpt: this.aumRpt,
      p_business_manager_desc: this.businessMgrDesc,
      p_taxability_desc: this.taxabilityDesc,
      p_sub_advisor_desc: this.subAdvisorDesc,
      p_sub_sub_advisor_desc: this.ssAdvisorDesc,
      p_acct_assistant_mgr_id: this.assitMgrID,
      p_acct_status: this.acctStatus,
      p_acct_name: this.accountName,
      p_tis_acct_type: this.accountType,
      p_gstatus: this.gStatus,
      p_xaum_flag: this.tempRecord.xaum_flag,
      p_acct_invst_adv_code: this.invstAdvID,
      p_acct_voting_authority: this.Voting,
      p_acct_invst_discretion: this.invstDis,
      p_acct_include_13fgd: this.invInclude,
      //p_first_effective_date: this.firstEffectiveDate,
      p_first_effective_date:
        this.firstEffectiveDate != null
          ? this.datepipe.transform(
              this.firstEffectiveDate,
              'MM/dd/yyyy hh:mm:ss a'
            )
          : this.firstEffectiveDate,
      p_last_effective_date:
        this.lastEffectiveDate != null
          ? this.datepipe.transform(
              this.lastEffectiveDate,
              'MM/dd/yyyy hh:mm:ss a'
            )
          : this.lastEffectiveDate,
    };
    console.log(accountacctinfo);

    this.accountservice.saveACCTAttributes(accountacctinfo).subscribe((res) => {
      this.toastr.success('Account details Updated Scuccessfully');
      this.getAccountSdmInformation();
    }),
      (err) => {
        this.toastr.error(err.error.message);
      };
  }
  deleteRecord() {
    if (confirm('Are you sure want to delete this record ? ')) {
      if (this.tempRecord['last_effective_date'] == null) {
        this.toastr.error('Delete is not allowed for First Record');
      } else if (
        this.lastRecord['acct_dim_key'] == this.tempRecord['acct_dim_key']
      ) {
        this.toastr.error('Delete is not allowed for Last Record');
      } else {
        this.accountservice
          .deleteHistoryACCTRecord(this.tempRecord['acct_dim_key'])
          .subscribe((result: any) => {
            if (result.status == 200) {
              if (result.data.error_message == null) {
                this.toastr.success('Record deleted successfully');
                this.getAccountSdmInformation();
              } else {
                this.toastr.error(result.data.error_message);
              }
            }
          }),
          (err) => {
            this.toastr.error(err.error.message);
          };
      }
    }
  }

  searchGrpmgr(event) {
    this.varGrpmgrText = event.target.value;
  }
  searchPort(event) {
    this.varPortText = event.target.value;
  }
  searchInvst(event) {
    this.varInvstText = event.target.value;
  }
  clearsearch = (event) => {
    this.varGrpmgrText = '';
    this.varPortText = '';
    this.varInvstText = '';

    event.target.value = '';
  };
}
