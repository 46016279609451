import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { TemplateModel1 } from 'src/app/models/dataloads';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { ToasterService } from 'src/app/services/toastr.service';
import { MatPaginator } from '@angular/material/paginator';
import { AumSdmShareClassService } from 'src/app/services/aum-sdm-share-class.service';
import { DatePipe, formatDate } from '@angular/common';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'aum-sdm-share-class',
  templateUrl: './aum-sdm-share-class.component.html',
  styleUrls: ['./aum-sdm-share-class.component.css'],
})
export class AumSDIMShareClassComponent implements OnInit {
  [x: string]: any;

  visible = true;
  accountNo: string = '';
  accountName: string = '';
  classcode: string = '';
  inceptDate: Date;
  terminateDate: Date;
  billingindiId: number = 0;
  approvalgrpid: number = 0;
  accountStatus: string = '';
  freqCode: string = '';
  lastUpdatedDate: string = '';
  lastUpdatedBy: string = '';
  AumFlag: boolean = false;
  isChecked: boolean = false;
  firstEffectiveDate: Date;
  lastEffectiveDate: Date;
  pageDirty: boolean = false;
  public tempRecord: any = [];
  public lastRecord: any = [];
  public firstRecord: any = [];
  isLoading: boolean = false;
  BillingIndList: any = [];
  BillingFilteredIndList: any = [];
  freqCodeList: any = [];
  AccountstatusList: any = [];
  ApprovalgrpList: any = [];
  validateinfo: string = '';
  isEdit: boolean = false;
  billingindiName: string = '';
  displayColumns = [
    'radio',
    'firstEffectiveDate',
    'lastEffectiveDate',
    'reportEligible',
    'acctStatus',
    'inceptDate',
    'termDate',
    'billingId',
    'billingName',
    'ApprGrpID',
    'ApprGrpName',
    'UpdateFrq',
  ];
  @ViewChild('tempsort', { static: true }) tempsort = new MatSort();
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('panel1') firstPanel: MatExpansionPanel;
  public templateList: TemplateModel1[] = [];
  public loadTemplateDatasource = new MatTableDataSource();
  panelOpenState: boolean;
  enable: boolean = false;
  showView: boolean = false;
  selectedrecord: string = '';
  varStatusText;
  varFreqText;
  varApprovalText;
  varBillText;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToasterService,
    private historyClass: AumSdmShareClassService,
    private datepipe: DatePipe
  ) {
    this.classcode = this.route.snapshot.paramMap.get('classcode');
    this.accountNo = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.getShareHistoryInfo();
    this.getBillingIndList();
    this.getfreqCodeList();
    this.getApprovalgrpList();
    this.getAccountstatusList();
  }
  getBillingIndList() {
    this.historyClass.getBillingInd().subscribe((result) => {
      this.BillingIndList = result.data;
    });
  }
  getfreqCodeList() {
    this.historyClass.getfreqCode().subscribe((result) => {
      this.freqCodeList = result.data;
    });
  }
  getAccountstatusList() {
    this.historyClass.getAccountstatus().subscribe((result) => {
      this.AccountstatusList = result.data;
    });
  }

  getApprovalgrpList() {
    this.historyClass.getApprovalgrp().subscribe((result) => {
      this.ApprovalgrpList = result.data;
    });
  }
  checkValue(event: any) {
    this.AumFlag = event;
  }
  getShareHistoryInfo() {
    this.isLoading = true;
    const accountacctinfo = {
      acct_num: this.accountNo,
      share_class_code: this.classcode,
    };
    this.historyClass
      .getSharehistInfo(accountacctinfo)
      .subscribe((result: any) => {
        if (result.status == 200 && result.data != null) {
          console.log(result.data);
          this.loadTemplateDatasource = new MatTableDataSource(result.data);
          this.loadTemplateDatasource.sort = this.tempsort;
          this.loadTemplateDatasource.paginator = this.paginator;
          this.firstRecord = result.data[0];
          this.lastRecord = result.data[result.data.length - 1];
          this.validateHistoryInformation();
          this.isLoading = false;
        }
      });
  }
  bindHtml(output: any) {
    let finalvalue = '';

    finalvalue = '<table><tr>';
    finalvalue += '<td class="col-16">' + output + '</td></tr></table>';

    return finalvalue;
  }
  validateHistoryInformation() {
    var outputdata: any[];
    // let messages='';
    this.historyClass
      .validateShareclassHistory(this.accountNo, this.classcode)
      .subscribe((result: any) => {
        if (result.status == 200 && result.data != null) {
          this.validateinfo = '<table><tr>';
          this.validateinfo +=
            '<td class="col-16"><font color="#FF0000">Data is inconsistent for the following effective dates:</font></td></tr></table>';

          if (result.data.meesage_1 != null) {
            outputdata = result.data.meesage_1.split('|');
            this.validateinfo +=
              '<table><tr><td class="col-16">First Effective date is greater than Last Effective date for :</td></tr>';
            this.validateinfo += '</table>';
            for (var xy of outputdata) {
              let myArray = xy.split('-');
              myArray.splice(0, 1);
              this.validateinfo += this.bindHtml(
                myArray[0] + ' - ' + myArray[1]
              );
            }
          }
          if (result.data.meesage_2 != null) {
            outputdata = result.data.meesage_2.split('|');
            this.validateinfo +=
              '<table><tr><td class="col-16">There is a time gap between the Last Effective date and the First Effective date for:</td></tr>';
            this.validateinfo += '</table>';
            for (var xy of outputdata) {
              let myArray = xy.split('-');
              myArray.splice(0, 1);
              this.validateinfo += this.bindHtml(
                myArray[0] + ' - ' + myArray[1]
              );
            }
          }
        }
      });
  }
  fillAccountInfo(source: any) {
    this.accountNo = source['aum_acct_num'];
    this.classcode = source['aum_share_class_code'];
    this.accountName = source['acct_name'];
    this.lastUpdatedDate = formatDate(
      source['system_last_updated_date'],
      'MM/dd/yyyy hh:mm:ss a',
      'en_US'
    );
    this.lastUpdatedBy = source['system_last_updated_by_user_id'];
    this.firstEffectiveDate =
      source['first_effective_date'] != null
        ? this.datepipe.transform(
            source['first_effective_date'],
            'MM/dd/yyyy hh:mm:ss a'
          )
        : source['first_effective_date'];
    this.lastEffectiveDate =
      source['last_effective_date'] != null
        ? this.datepipe.transform(
            source['last_effective_date'],
            'MM/dd/yyyy hh:mm:ss a'
          )
        : source['last_effective_date'];
    this.terminateDate =
      source['aum_termination_date'] != null
        ? this.datepipe.transform(source['aum_termination_date'], 'yyyy-MM-dd')
        : source['aum_termination_date'];
    this.inceptDate =
      source['aum_incept_date'] != null
        ? this.datepipe.transform(source['aum_incept_date'], 'yyyy-MM-dd')
        : source['aum_incept_date'];
    this.billingindiId = source['aum_billing_indicator_dim_id'];
    this.billingindiName = source['aum_billing_indicator_name'];
    this.approvalgrpid = source['aum_apprvl_group_dim_id'];
    this.accountStatus = source['aum_acct_status'];
    this.freqCode = source['aum_update_freq_code'];
    this.AumFlag = source['report_eligibility_flag'] == 'Active' ? true : false;
    this.enable = source['last_effective_date'] != null ? false : true;
  }


  viewAttribute(event: any) {
    this.tempRecord = event.value;
  }
  redirectToXHLDProfile() {
    this.router.navigate([
      '/xhld-profile-assoc-sdim',
      { id: this.accountNo, classcode: this.classcode },
    ]);
  }
  redirectToSharePage() {
    this.router.navigate([
      '/share-class-attributes',
      { id: this.accountNo, classcode: this.classcode },
    ]);
  }
  closePanel() {
    this.firstPanel.close();
    this.pageDirty = false;
  }

  loadDetailsPanel() {
    this.isEdit = false;
    this.pageDirty = true;
    if (this.tempRecord['aum_sdim_key'] != undefined) {
      this.showView = true;
      this.selectedrecord = this.tempRecord['aum_sdim_key'];
      this.firstPanel.open();

      this.BillingFilteredIndList = this.BillingIndList.filter(
        (x: any) => x.aum_billing_indicator_status == 'Active'
      );

      let obj = this.BillingFilteredIndList.filter(
        (x: any) =>
          x.aum_billing_indicator_dim_id ==
          this.tempRecord['aum_billing_indicator_dim_id']
      );

      if (obj.length == 0) {
        this.BillingFilteredIndList.push({
          aum_billing_indicator_dim_id:
            this.tempRecord['aum_billing_indicator_dim_id'],
          aum_billing_indicator_name:
            this.tempRecord['aum_billing_indicator_name'],
        });
      }
      this.fillAccountInfo(this.tempRecord);

      //if (this.firstRecord['aum_sdim_key'] != this.tempRecord['aum_sdim_key']) {
        //this.isEdit = true;
      //}
      if((this.tempRecord['last_effective_date'] == null || this.tempRecord["last_effective_date"] == "")){
        this.isEdit = false;
      }else{
        this.isEdit = true;
      }
    }
  }
  copyAsNewRecord() {
    const accountinfo = {
      aum_sdim_key: this.tempRecord['aum_sdim_key'],
    };
    this.historyClass
      .copyHistoryshareClass(accountinfo)
      .subscribe((result: any) => {
        if (result.status == 200 || result.status == 201) {
          this.toastr.success('New Record created Scuccessfully');
          this.getShareHistoryInfo();
        }
      });
  }
  deleteRecord() {
    if (confirm('Are you sure want to delete this record ? ')) {
      if (this.tempRecord['last_effective_date'] == null) {
        this.toastr.error('Delete is not allowed for First Record');
      } else if (
        this.lastRecord['aum_sdim_key'] == this.tempRecord['aum_sdim_key']
      ) {
        this.toastr.error('Delete is not allowed for Last Record');
      } else {
        this.historyClass
          .deleteHistoryshareClass(this.tempRecord['aum_sdim_key'])
          .subscribe((result: any) => {
            if (result.status == 200) {
              if (result.data.error_message == null) {
                this.toastr.success('Record deleted successfully');
                this.getShareHistoryInfo();
              } else {
                this.toastr.error(result.data.error_message);
              }
            }
          }),
          (err) => {
            this.toastr.error(err.error.message);
          };
      }
    }
  }
  saveAccountInfo() {
    const accountacctinfo = {
      aum_sdim_key: this.tempRecord['aum_sdim_key'],
      inception_date:
        this.inceptDate != null
          ? formatDate(
              this.inceptDate,
              'MM/dd/yyyy hh:mm:ssZZZZZ',
              'en_US'
            ).slice(0, 10)
          : this.inceptDate,
      acct_status: this.accountStatus,
      term_date:
        this.terminateDate != null
          ? formatDate(
              this.terminateDate,
              'MM/dd/yyyy hh:mm:ssZZZZZ',
              'en_US'
            ).slice(0, 10)
          : this.terminateDate,
      update_freq: this.freqCode,
      approval_group_id: this.approvalgrpid,
      first_effective_date:
        this.firstEffectiveDate != null
          ? this.datepipe.transform(
              this.firstEffectiveDate,
              'MM/dd/yyyy hh:mm:ss a'
            )
          : this.firstEffectiveDate,
      last_effective_date:
        this.lastEffectiveDate != null
          ? this.datepipe.transform(
              this.lastEffectiveDate,
              'MM/dd/yyyy hh:mm:ss a'
            )
          : this.lastEffectiveDate,
      aum_report_flag: this.AumFlag == true ? 'Active' : 'InActive',
      aum_billing_indicator_dim_id: this.billingindiId,
    };

    this.historyClass
      .saveHistoryshareClass(accountacctinfo)
      .subscribe((res) => {
        this.toastr.success('Account details Updated Scuccessfully');
        this.getShareHistoryInfo();
      }),
      (err) => {
        this.toastr.error(err.error.message);
      };
  }

  searchStatus(event) {
    this.varStatusText = event.target.value;
  }

  searchFreq(event) {
    this.varFreqText = event.target.value;
  }

  searchApproval(event) {
    this.varApprovalText = event.target.value;
  }
  searchBill(event) {
    this.varBillText = event.target.value;
  }

  clearsearch = (event) => {
    this.varStatusText = '';
    this.varFreqText = '';
    this.varApprovalText = '';
    this.varBillText = '';
    event.target.value = '';
  };
  canDeactivate(): Observable<boolean> | boolean {
    if (this.pageDirty) {
      return this.confirmDialog.confirm('Are you sure you want to leave?');
    }
    return true;
  }
}
